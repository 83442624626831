module.exports = [{
      plugin: require('/opt/build/repo/node_modules/@raae/gatsby-remark-oembed/gatsby-browser.js'),
      options: {"plugins":[],"navigateFallback":null,"navigateFallbackWhitelist":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[],"navigateFallback":null,"navigateFallbackWhitelist":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"RkOVgrq95SpTAxLiircyuR5jvQ29jC8j","trackPage":true},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
